section.signup {
    height: 100vh;
    display: flex;
    justify-content: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 20px 20px;
}

h2.form-title {
    color: #fff;
}
.signup-container {
    width: 100%;
    max-width: 950px;
    margin: auto;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    filter: drop-shadow(0px 7px 3.5px rgba(1,1,1,0.35));
    padding: 50px;
    border: 1px solid rgb(216, 213, 213);

}

.signup-content {
    display: flex;
    align-items: center;
}

.signup-content .signup-form,
.signup-img {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
}

.signup-img {
    text-align: center;
}

.wrapper .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 20px 0;
}

.input-box input {
    width: 80%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: none;
    font-size: 16px;
    color: #fff;
    padding: 10px 10px 0px 70px;
    border-bottom: 1px solid grey;
}

.input-box input::selection{background-color: transparent;}
.input-box input::placeholder {
    color: #fff;
    transition: color 0.3s ease;
}

.input-box .icon {
    position: absolute;
    left: 20px;
    top: 60%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
}

.input-box select.countrycode {
    position: absolute;
    left: 10px;
    top: 60%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #fff;
    border: none;
    background: transparent;
}

.input-box select:focus {
    outline: none;
    background: transparent;
}

button.signup_btn {
    width: 152px;
    padding: 15px 10px;
    border-radius: 24px;
    color: #000;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 7px 7px 0px rgba(1, 1, 1, 0.35);
    border: none;
    margin-top: 20px;
}
button.signup_btn:hover {
    transform: scale(1.10);
}
input:focus::placeholder {
    color: transparent;
    transition: 0.3s ease;
}

p.errorMessage {
    background: #64adad;
    padding: 5px 14px;
    max-width: max-content;
    margin: 10px auto 0;
    border-radius: 15px;
}


@media (max-width:767px) {
    .signup-img {
        display: none;
    }

    section.signup {
        padding: 20px;
    }

    .signup-content .signup-form {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width:369px) {
    .input-box input {
        width: 100%;
    }

    .input-box input::placeholder {
        font-size: 10px;
    }
}