.hidden_form {
    display: none;
  }
  .show_form {
    display: block;
  }

  /* .hidden_form{
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    right: 0;
  }
  .show_form{
    visibility: visible;
    opacity: 999;
    width: 100%;
    transition: 0.3s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  } */

  
  .name_ngo {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .title_head {
    color: #fff;
    font-weight: 300;
    letter-spacing: 3px;
  }
  
  .form_sec {
    margin-top: 50px;
  }
  
  label.que_head {
    font-size: 22px;
    color: #fff;
    font-weight: 300;
  }
  
  .form_sec input#input_block1,
  .form_sec input#input_block2,
  .form_sec input#input_block3,
  .form_sec input#input_block4,
  textarea#input_block1,
  textarea#input_block2,
  textarea#input_block3,
  textarea#input_block4 {
    width: 400px;
    padding: 18px 20px;
    border-radius: 50px;
    border: 1px solid #fff;
    background: transparent;
    outline: none;
    color: #fff;
    margin: 20px 0;
  }
  .form_sec input#input_block1::placeholder,
  .form_sec input#input_block2::placeholder,
  .form_sec input#input_block3::placeholder,
  .form_sec input#input_block4::placeholder,
  .form_sec textarea#input_block1::placeholder,
  .form_sec textarea#input_block2::placeholder,
  .form_sec textarea#input_block3::placeholder,
  .form_sec textarea#input_block4::placeholder {
    color: #a6a5a6;
    font-weight: 100px;
    letter-spacing: 0.15px;
    font-size: 14px;
  }
  .form_sec input:hover {
    box-shadow: 0px 7px 3.5px rgba(1, 1, 1, 0.15);
  }
  
  .form_submit button {
    border: none;
    padding: 15px 25px;
    font-size: 15px;
    color: #fff;
    border-radius: 10px;
    background: transparent;
  }
  .form_submit button.empty {
    opacity: 0.5;
  }
  
  .form_submit button{
    margin: 10px;
  }
  .form_submit button svg {
    fill: #fff;
}
button.previousBtn svg {transform: rotate(180deg);}
  .form_submit button.notEmpty {
    opacity: 1;
    cursor: pointer;
  }
  .form_sec input#input_block1:focus,
  .form_sec input#input_block3:focus,
  .form_sec input#input_block4:focus{
    outline: none;
  }
  .form_sec input#input_block2:focus {
    outline: none;
  }
  .name_ngo_sec {
    margin: 0 auto;
  }
  
  .form_submit {
    display: flex;
    justify-content: center;
  }
  .result_sec {
    margin-top: 50px;
  }
  .result_sec ul li {
    text-align: left;
    list-style: none;
    color: #fff;
    margin: 15px 0;
    font-size: 20px;
  }
  
  textarea#input_block1, textarea#input_block2, textarea#input_block3, textarea#input_block4{
    min-height: 90px;
  }
  .flex_form{
    display: flex;
    justify-content: center;
    column-gap: 15px;
    align-items: center;
  }
  
  .flex_form select{
    width: 80px;
    padding: 18px 10px;
    border-radius: 50px;
    border: 1px solid #fff;
    background: transparent;
    outline: none;
    color: #fff;
    height: 100%;
    margin:20px 0;
  }
  .flex_form select:focus {
    border-color: #a8a8a8;
    outline: none;
    background: transparent;
    color: #000
  }
  .result_table{
    margin-top: 25px;
  }
  /* table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }
  td{
    color: #fff;
    font-weight: 100;
    font-size: 13px;
  } */
  h6{
    font-size: 15px;
    margin-top: 50px;
  }
  /* button.last{
    background: transparent;
    width: 100%;
    border: none;
  } */
  
  /* button.last a{
    text-decoration: none;
    display: inline-block;
    padding: 15px 60px;
    width: max-content;
    height: 100%;
    border-radius: 24px;
    filter: drop-shadow(0px 7px 3.5px rgba(1,1,1,0.35));
    background-color: #292929;
    color: #fff;
  } */

  .form_first.show_form .form_submit svg {
    fill: #fff;
}


  /* RESULT PAGE */
  .result-inr {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
}

.result-inner-box {
    flex: 0 0 48%;
    max-width: 48%;
    color: #fff;
}

.result_value {
    padding: 15px 30px;
    border: 1px solid #fff;
    border-radius: 50px;
    margin-top: 8px;
}

.result_head {
    text-align: left;
    padding-left: 20px;
    margin-top: 30px;
    font-size: 15px;
}

h6 {
    color: #fff;
}
a.chatbotLink {
  color: #fff;
}

a.chatbotLink {
  color: #fff;
}
  
  @media (max-width:767px){
    .title_head{
      letter-spacing: 1px;
      font-size: 28px;
    }
  }
  
  @media (max-width:560px){
    .form_sec input#input_block1,
    .form_sec input#input_block2, 
    .form_sec input#input_block3,
    .form_sec input#input_block4, 
    textarea#input_block1,
    textarea#input_block2,
    textarea#input_block3,
    textarea#input_block4{
      width: 200px;
    }
    .title_head{
      font-size: 23px;
    }
    label.que_head {
      font-size: 25px;
  }
  .form_submit{
    justify-content: center ;
  }
  .result-inner-box{flex: 0 0 100%;max-width: 100%;}
  }
  
  @media (max-width:39px){
    .form_submit {
      flex-direction: column;
      gap: 10px;
      max-width: 100px;
      margin: 0 auto;
  }
  }